(function () {
  'use strict';

  class LearningCtrl {
    constructor() {
      let vm = this;
      vm.ctrlName = 'LearningCtrl';
    }
  }

  /**
   * @ngdoc object
   * @name dashboard.learning.controller:LearningCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard.learning')
    .controller('LearningCtrl', LearningCtrl);
}());
